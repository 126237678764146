var templater = require("handlebars/runtime")["default"].template;module.exports = templater({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"info-window-route-template\" class=\"Map-markerContent hidden-xs text-center clearfix mx-0\">\r\n\r\n  <h4 class=\"LocalAreaInfoWindow__destination-name my-0\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":3,"column":57},"end":{"line":3,"column":65}}}) : helper)))
    + "</h4>\r\n\r\n  <h4 class=\"LocalAreaInfoWindow__travel-time my-4\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"travelTime") || (depth0 != null ? lookupProperty(depth0,"travelTime") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"travelTime","hash":{},"data":data,"loc":{"start":{"line":5,"column":52},"end":{"line":5,"column":66}}}) : helper)))
    + "</h4>\r\n\r\n  <a href=\"https://maps.google.com/maps/dir/?api=1&origin="
    + alias4(((helper = (helper = lookupProperty(helpers,"originLatitude") || (depth0 != null ? lookupProperty(depth0,"originLatitude") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"originLatitude","hash":{},"data":data,"loc":{"start":{"line":7,"column":58},"end":{"line":7,"column":76}}}) : helper)))
    + ","
    + alias4(((helper = (helper = lookupProperty(helpers,"originLongitude") || (depth0 != null ? lookupProperty(depth0,"originLongitude") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"originLongitude","hash":{},"data":data,"loc":{"start":{"line":7,"column":77},"end":{"line":7,"column":96}}}) : helper)))
    + "&destination="
    + alias4(((helper = (helper = lookupProperty(helpers,"destinationLatitude") || (depth0 != null ? lookupProperty(depth0,"destinationLatitude") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"destinationLatitude","hash":{},"data":data,"loc":{"start":{"line":7,"column":109},"end":{"line":7,"column":132}}}) : helper)))
    + ","
    + alias4(((helper = (helper = lookupProperty(helpers,"destinationLongitude") || (depth0 != null ? lookupProperty(depth0,"destinationLongitude") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"destinationLongitude","hash":{},"data":data,"loc":{"start":{"line":7,"column":133},"end":{"line":7,"column":157}}}) : helper)))
    + "\"\r\n    target=\"_blank\" rel=\"noopener\" class=\"LocalAreaInfoWindow__google-maps-link\">\r\n    "
    + alias4(((helper = (helper = lookupProperty(helpers,"googleMapsLinkText") || (depth0 != null ? lookupProperty(depth0,"googleMapsLinkText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"googleMapsLinkText","hash":{},"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":9,"column":26}}}) : helper)))
    + "\r\n  </a>\r\n</div>\r\n";
},"useData":true});