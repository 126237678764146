var templater = require("handlebars/runtime")["default"].template;module.exports = templater({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"TypeAhead-results\">\r\n    <div class=\"TypeAhead-resultsList\"></div>\r\n    <hr>\r\n    <button class=\"btn btn-primary\" type=\"submit\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"buttonValue") || (depth0 != null ? lookupProperty(depth0,"buttonValue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"buttonValue","hash":{},"data":data,"loc":{"start":{"line":4,"column":50},"end":{"line":4,"column":65}}}) : helper)))
    + "</button>\r\n</div>\r\n";
},"useData":true});