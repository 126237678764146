var component = require('../../../lib/js/component.js');
var requestInfo = require('../../../lib/js/request-info.js');
var utils = require('../../../lib/js/utils.js');

var ResponsiveMedia = require('../responsive-media/responsive-media.js');

function CarouselV2() {
    var component;

    var navIconLeft = "icon-arrow-left",
        navIconRight = "icon-arrow-right";

    var navText = [
        '<span class="sr-only">Previous</span><i class="' + navIconLeft + '"></i>',
        '<span class="sr-only">Next</span><i class="' + navIconRight + '"></i>'
    ];

    var _options = {
        dots: false,
        items: 1,
        loop: true,
        nav: true,
        navElement: 'button',
        navSpeed: 300,
        navText: navText,
        onInitialized: onCarouselInitialized,
        onTranslated: onCarouselTranslated
    };

    $(document).on('click', '.owl-prev, .owl-next, .Carousel-v2 .Share-icon, .Carousel-v2 .PinterestLink', function (event) {
        event.stopPropagation();
    });

    $(document).on('click', '.Carousel-v2 .lead-form-submit, .dual-lead-form-submit', function (e) {
        handleLeadFormSubmit(e);
        $("#communitySpecificForm input[name=CommunityId]").val(0);
        requestInfo.initRequestInfoModals();
    });

    var _triggerResizeEvent = utils.debounce(function () {
        window.dispatchEvent(new Event('resize')); // This is called to force the Carousel to recalculate its visible dimensions.
    }, 250, false);

    this.initialize = function (element, options) {
        var _this = this;
        component = this;
        _this.slidesAreLoaded = false;

        this.carouselGuid = utils.generateGuid();
        this.$element.attr("id", "Carousel-V2-" + this.carouselGuid);
        this.$element.attr("data-guid", this.carouselGuid);

        // Load all images in carousel and related slide show on any interaction
        this.$element.on('changed.owl.carousel', function (event) {
            component.handleChangedOwlCarousel(_this, event);
        });

        // Initialize carousel
        this.$element.find(".Carousel").owlCarousel(_options);

        // Initialize lead form
        this.initializeLeadForm(this.$element);

        this.on('keyup', function (e) {
            if (e.which == 9) {
                var cloneItems = this.$element.find('.owl-item.cloned:not(.active) a.Social-btn');
                $.each(cloneItems, function (index, item) {
                    $(item).attr('tabindex', -1);
                });
            }
        });

        // Add event listener when CTA is clicked
        var id = "#Carousel-V2-" + this.carouselGuid;
        component.on('click', id + ' .Carousel-cta', this.onCarouselCtaClicked);
    }

    function handleLeadFormSubmit(event) {
        var $leadFormSlide = $(event.currentTarget).closest('.LeadFormSlide');
        var $firstNameField = $leadFormSlide.find('input[name=FirstName');
        var $lastNameField = $leadFormSlide.find('input[name=LastName');
        var $emailField = $leadFormSlide.find('input[name=Email');
        var $zipCodeField = $leadFormSlide.find('input[name=ZipCode');
        var $phoneNumberField = $leadFormSlide.find('input[name=Phone');
        var $communityModalLeadForm = $(".CommunityModalLeadForm form[name=CommunityLeadForm]");

        $communityModalLeadForm.find('input[name=FirstName').val($firstNameField.val());
        $communityModalLeadForm.find('input[name=LastName').val($lastNameField.val());
        $communityModalLeadForm.find('input[name=Email').val($emailField.val());
        $communityModalLeadForm.find('input[name=ZipCode').val($zipCodeField.val());
        $communityModalLeadForm.find('input[name=Phone').val($phoneNumberField.val());
    }

    function onCarouselInitialized(event) {
        _triggerResizeEvent();

        component.setAriaLabels();
        component.setTabIndex();

        component.disableSingleImageCarouselSwipe();
    }

    function onCarouselTranslated(event) {
        event.preventDefault();

        component.setAriaLabels();
        component.setTabIndex();
    }

    this.onCarouselCtaClicked = function (event) {
        var deeplinkId = $(this).data("deeplinkid");
        if (deeplinkId) {
            deeplinkId = deeplinkId.toString().trim('#');

            var element = document.getElementById(deeplinkId);
            if (element) {
                var boundingClientRect = element.getBoundingClientRect();
                var offset = 100;
                var calculatedTop = boundingClientRect.top + window.scrollY - offset;
                var currentTop = calculatedTop;
                var left = boundingClientRect.left

                // Page will scroll to initial calculated position of the target element
                window.scroll({
                    top: calculatedTop,
                    left: left,
                    behavior: "smooth"
                });

                // While the page scrolls, images will load in which will push the target element further down the page
                // This setInterval will recalculate for the target element's position until the page finally scrolls to the correct position
                var intervalId = setInterval(function () {
                    boundingClientRect = element.getBoundingClientRect();
                    calculatedTop = boundingClientRect.top + window.scrollY - offset;

                    if (currentTop !== calculatedTop) {
                        window.scroll({
                            top: calculatedTop,
                            left: left,
                            behavior: "smooth"
                        });

                        currentTop = calculatedTop;
                    }
                    else if (currentTop === calculatedTop) { // When the correct position for the target element is finally reached, this interval will be cleared
                        clearInterval(intervalId);
                    }
                }, 500);
            }
        }

        event.stopPropagation();
    }

    this.handleChangedOwlCarousel = function (scope, event) {
        if (event.property && event.property.name == 'position') { // Only listen to the position changed event

            // make screen reader re-read nav button
            var $focusedElement = $(':focus');

            if ($focusedElement.length > 0) {
                var $parent = $focusedElement.parent();
                $focusedElement.detach();
                setTimeout(function() {
                    if ($focusedElement.hasClass('owl-next'))
                        $parent.append($focusedElement);
                    else
                        $parent.prepend($focusedElement);
    
                    $focusedElement.trigger('focus');
                }, 100);
            }

            if (!scope.slidesAreLoaded) {

                var items = scope.$element.find('.owl-item');
                items.each(function () {
                    var $element = $(this);
                    $responsiveImg = $element.find('img.u-responsiveMedia');

                    // Cloned items are not in DOM at page load and not processed by responsive media
                    if ($element.hasClass('cloned')) {
                        $responsiveImg.attach(ResponsiveMedia);
                    }

                    $responsiveImg.trigger("RESPONSIVE_LAZYLOAD");

                    // Avoid applying inline display: block to images within .no-display-block containers
                    if ($responsiveImg.closest('.no-display-block').length === 0) {
                        $responsiveImg.on('load', function () {
                            $element.css('display', 'block');
                        });
                    }
                });

                if (items.length > 0) {
                    scope.$element.cloudinary_update();
                    $.cloudinary.responsive();
                    scope.slidesAreLoaded = true;
                }
            }
        }
    }

    this.initializeLeadForm = function ($element) {
        var $phoneInputs = $element.find('input[name="Phone"]');
        if ($phoneInputs && $phoneInputs.length) {
            for (var index = 0; index < $phoneInputs.length; index++) {
                var phoneInput = $phoneInputs[index];

                $(phoneInput).on("change", this.formatPhoneNumber.bind(this));
            }
        }
    }

    this.setAriaLabels = function () {

        var $owlItems = component.find('.owl-item');
        var $carouselSlides = $owlItems.not('.active').find('.Carousel-slide');
        $carouselSlides.attr('aria-hidden', true);
        var $activeSlide = $owlItems.find('.active');
        $activeSlide.find('.Carousel-slide').removeAttr('aria-hidden');
    }

    this.setTabIndex = function () {
        component.find('.owl-prev, .owl-item a, .owl-next, .owl-item .Image-caption, .owl-item img').attr('tabindex', -1);

        component.selectable = [];

        component.selectable.push(component.find('.carousel-modal-close')[0]);

        var $owlItems = component.find('.owl-item');
        var $activeOwlItem = $owlItems.find('.active');
        var $inactiveOwlItems = $owlItems.not('.active');

        var leadForm = $activeOwlItem.find('.Carousel-slide__lead-form');
        if (leadForm && leadForm.length) {
            leadForm.find('input, .submit-button').each(function (index, input) {
                component.selectable.push(input);
            });
        }
        component.selectable.push(component.find('.owl-prev')[0]);


        $inactiveOwlItems.find('a,button,img,.Image-caption').attr('tabindex', -1).attr('aria-hidden', true);

        //remove focus from lead form input
        $inactiveOwlItems.find("input,.icon-close").attr('tabindex', -1);

        var carouselSlideItems =
            $activeOwlItem.find('a, button, img, .Image-caption').not('.submit-button');

        $.each(carouselSlideItems, function (index, item) {
            component.selectable.push(item);
        });


        component.selectable.push(component.find('.owl-next')[0]);

        $.each(component.selectable, function (index, item) {
            $(item).attr({ 'tabindex': 0, 'aria-hidden': false });
        });
    }

    // Helpers
    this.formatPhoneNumber = function (event) {
        var phoneInput = event.target;
        if (phoneInput) {
            $(phoneInput).val(utils.formatDirtyPhoneNumber($(phoneInput).val()));
        }
    }

    this.disableSingleImageCarouselSwipe = function () {
        // Single Image Carousels have 5 cloned items
        var isSingleImageCarousel = component.find('.owl-item').length === 5;
        if (isSingleImageCarousel) {
            component.find('.owl-stage').css({ 'pointer-events': 'none', 'cursor': 'pointer' });
            component.find('.Social-btn, .Share-icon').css('pointer-events', 'all');
        }
    }
}

module.exports = component(CarouselV2);
