var templater = require("handlebars/runtime")["default"].template;module.exports = templater({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "Map-markerContent-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"kind") || (depth0 != null ? lookupProperty(depth0,"kind") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"kind","hash":{},"data":data,"loc":{"start":{"line":1,"column":102},"end":{"line":1,"column":110}}}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <ul class=\"clearfix\">\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"startingFromPrice") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":17,"column":15}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"minBedrooms") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":24,"column":15}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"minBathrooms") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":8},"end":{"line":31,"column":15}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"minGarage") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":33,"column":9},"end":{"line":43,"column":15}}})) != null ? stack1 : "")
    + "        \r\n    </ul>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>\r\n            <span class=\"markerDetail-data\">$"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"startingFromPrice") || (depth0 != null ? lookupProperty(depth0,"startingFromPrice") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"startingFromPrice","hash":{},"data":data,"loc":{"start":{"line":9,"column":45},"end":{"line":9,"column":66}}}) : helper)))
    + "</span>\r\n            <span class=\"markerDetail-label\">Starting At</span>\r\n        </li>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"markerDetail--status\">\r\n            <span class=\"markerDetail-data\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"priceStatus") || (depth0 != null ? lookupProperty(depth0,"priceStatus") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"priceStatus","hash":{},"data":data,"loc":{"start":{"line":14,"column":44},"end":{"line":14,"column":59}}}) : helper)))
    + "</span>\r\n            <span class=\"markerDetail-label\"></span>\r\n        </li>\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>\r\n            <span class=\"markerDetail-data\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"minBedrooms") || (depth0 != null ? lookupProperty(depth0,"minBedrooms") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"minBedrooms","hash":{},"data":data,"loc":{"start":{"line":21,"column":44},"end":{"line":21,"column":59}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"maxBedrooms") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":59},"end":{"line":21,"column":101}}})) != null ? stack1 : "")
    + "</span>\r\n            <span class=\"markerDetail-label\">Bedrooms</span>\r\n        </li>\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"maxBedrooms") || (depth0 != null ? lookupProperty(depth0,"maxBedrooms") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"maxBedrooms","hash":{},"data":data,"loc":{"start":{"line":21,"column":79},"end":{"line":21,"column":94}}}) : helper)));
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>\r\n            <span class=\"markerDetail-data\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"minBathrooms") || (depth0 != null ? lookupProperty(depth0,"minBathrooms") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"minBathrooms","hash":{},"data":data,"loc":{"start":{"line":28,"column":44},"end":{"line":28,"column":60}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"maxBathrooms") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":60},"end":{"line":28,"column":104}}})) != null ? stack1 : "")
    + "</span>\r\n            <span class=\"markerDetail-label\">Bathrooms</span>\r\n        </li>\r\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"maxBathrooms") || (depth0 != null ? lookupProperty(depth0,"maxBathrooms") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"maxBathrooms","hash":{},"data":data,"loc":{"start":{"line":28,"column":81},"end":{"line":28,"column":97}}}) : helper)));
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"hidden-xs\">\r\n            <span class=\"markerDetail-data\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"minGarage") || (depth0 != null ? lookupProperty(depth0,"minGarage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"minGarage","hash":{},"data":data,"loc":{"start":{"line":35,"column":44},"end":{"line":35,"column":57}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"maxGarage") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":57},"end":{"line":35,"column":95}}})) != null ? stack1 : "")
    + "</span>\r\n            <span class=\"markerDetail-label\">Garage</span>\r\n        </li>\r\n";
},"15":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"maxGarage") || (depth0 != null ? lookupProperty(depth0,"maxGarage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"maxGarage","hash":{},"data":data,"loc":{"start":{"line":35,"column":75},"end":{"line":35,"column":88}}}) : helper)));
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"maxGarage") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":8},"end":{"line":43,"column":8}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"hidden-xs\">\r\n            <span class=\"markerDetail-data\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"minGarage") || (depth0 != null ? lookupProperty(depth0,"minGarage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"minGarage","hash":{},"data":data,"loc":{"start":{"line":40,"column":44},"end":{"line":40,"column":57}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"maxGarage") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":57},"end":{"line":40,"column":95}}})) != null ? stack1 : "")
    + "</span>\r\n            <span class=\"markerDetail-label\">Garage</span>\r\n        </li>\r\n        ";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"CommunityLink") || (depth0 != null ? lookupProperty(depth0,"CommunityLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CommunityLink","hash":{},"data":data,"loc":{"start":{"line":50,"column":21},"end":{"line":50,"column":38}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"minBedrooms") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":40},"end":{"line":50,"column":96}}})) != null ? stack1 : "")
    + "class=\"btn btn-primary marker-community-link\" data-analytics=\"map|window-info|view-community\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"ctaText") || (depth0 != null ? lookupProperty(depth0,"ctaText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ctaText","hash":{},"data":data,"loc":{"start":{"line":50,"column":190},"end":{"line":50,"column":201}}}) : helper)))
    + "</a>\r\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "target=\"_blank\" rel=\"noopener\"";
},"23":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"CommunityLink") || (depth0 != null ? lookupProperty(depth0,"CommunityLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CommunityLink","hash":{},"data":data,"loc":{"start":{"line":53,"column":21},"end":{"line":53,"column":38}}}) : helper)))
    + "#qmi\" target=\"_blank\" rel=\"noopener\" class=\"btn btn-default marker-qmi-link\" data-analytics=\"map|window-info|view-community-qmi\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"ctaQmiText") || (depth0 != null ? lookupProperty(depth0,"ctaQmiText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ctaQmiText","hash":{},"data":data,"loc":{"start":{"line":53,"column":167},"end":{"line":53,"column":181}}}) : helper)))
    + "</a>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"info-window-nearby-template\" class=\"Map-markerContent clearfix "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"kind") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":72},"end":{"line":1,"column":117}}})) != null ? stack1 : "")
    + "\">\r\n    <h3 class=\"marker-name\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":2,"column":28},"end":{"line":2,"column":36}}}) : helper)))
    + "</h3>\r\n    <hr>\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"kind") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":46,"column":15}}})) != null ? stack1 : "")
    + "\r\n    <div class=\"marker-links\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"CommunityLink") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":8},"end":{"line":51,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"inventoryCount") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":8},"end":{"line":54,"column":15}}})) != null ? stack1 : "")
    + "    </div>\r\n  \r\n</div>\r\n";
},"useData":true});