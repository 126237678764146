var templater = require("handlebars/runtime")["default"].template;module.exports = templater({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"markerDetail--status\">\r\n            <span class=\"markerDetail-data\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"communityStatus") || (depth0 != null ? lookupProperty(depth0,"communityStatus") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"communityStatus","hash":{},"data":data,"loc":{"start":{"line":9,"column":44},"end":{"line":9,"column":63}}}) : helper)))
    + "</span>\r\n            <span class=\"markerDetail-label\"></span>\r\n        </li>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"startingFromPrice") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":29,"column":8}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>\r\n            <span class=\"markerDetail-data\">$"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"startingFromPrice") || (depth0 != null ? lookupProperty(depth0,"startingFromPrice") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"startingFromPrice","hash":{},"data":data,"loc":{"start":{"line":14,"column":45},"end":{"line":14,"column":66}}}) : helper)))
    + "</span>\r\n            <span class=\"markerDetail-label\">Starting At</span>\r\n        </li>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"comingPricePrefix") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":29,"column":8}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"comingPriceRange") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":10},"end":{"line":23,"column":17}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <li class=\"markerDetail--status\">\r\n              <span class=\"markerDetail-data\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"comingPricePrefix") || (depth0 != null ? lookupProperty(depth0,"comingPricePrefix") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"comingPricePrefix","hash":{},"data":data,"loc":{"start":{"line":20,"column":46},"end":{"line":20,"column":67}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"comingPriceRange") || (depth0 != null ? lookupProperty(depth0,"comingPriceRange") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"comingPriceRange","hash":{},"data":data,"loc":{"start":{"line":20,"column":68},"end":{"line":20,"column":88}}}) : helper)))
    + "</span>\r\n              <span class=\"markerDetail-label\"></span>\r\n          </li>\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"markerDetail--status\">\r\n            <span class=\"markerDetail-data\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"priceStatus") || (depth0 != null ? lookupProperty(depth0,"priceStatus") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"priceStatus","hash":{},"data":data,"loc":{"start":{"line":26,"column":44},"end":{"line":26,"column":59}}}) : helper)))
    + "</span>\r\n            <span class=\"markerDetail-label\"></span>\r\n        </li>\r\n        ";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>\r\n            <span class=\"markerDetail-data\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"minBedrooms") || (depth0 != null ? lookupProperty(depth0,"minBedrooms") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"minBedrooms","hash":{},"data":data,"loc":{"start":{"line":33,"column":44},"end":{"line":33,"column":59}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"maxBedrooms") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":59},"end":{"line":33,"column":101}}})) != null ? stack1 : "")
    + "</span>\r\n            <span class=\"markerDetail-label\">Bedrooms</span>\r\n        </li>\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"maxBedrooms") || (depth0 != null ? lookupProperty(depth0,"maxBedrooms") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"maxBedrooms","hash":{},"data":data,"loc":{"start":{"line":33,"column":79},"end":{"line":33,"column":94}}}) : helper)));
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>\r\n            <span class=\"markerDetail-data\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"minBathrooms") || (depth0 != null ? lookupProperty(depth0,"minBathrooms") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"minBathrooms","hash":{},"data":data,"loc":{"start":{"line":40,"column":44},"end":{"line":40,"column":60}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"maxBathrooms") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":60},"end":{"line":40,"column":104}}})) != null ? stack1 : "")
    + "</span>\r\n            <span class=\"markerDetail-label\">Bathrooms</span>\r\n        </li>\r\n";
},"16":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"maxBathrooms") || (depth0 != null ? lookupProperty(depth0,"maxBathrooms") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"maxBathrooms","hash":{},"data":data,"loc":{"start":{"line":40,"column":81},"end":{"line":40,"column":97}}}) : helper)));
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"hidden-xs\">\r\n            <span class=\"markerDetail-data\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"minGarage") || (depth0 != null ? lookupProperty(depth0,"minGarage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"minGarage","hash":{},"data":data,"loc":{"start":{"line":47,"column":44},"end":{"line":47,"column":57}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"maxGarage") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":57},"end":{"line":47,"column":95}}})) != null ? stack1 : "")
    + "</span>\r\n            <span class=\"markerDetail-label\">Garage</span>\r\n        </li>\r\n";
},"19":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"maxGarage") || (depth0 != null ? lookupProperty(depth0,"maxGarage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"maxGarage","hash":{},"data":data,"loc":{"start":{"line":47,"column":75},"end":{"line":47,"column":88}}}) : helper)));
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"maxGarage") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":8},"end":{"line":55,"column":8}}})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"hidden-xs\">\r\n            <span class=\"markerDetail-data\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"minGarage") || (depth0 != null ? lookupProperty(depth0,"minGarage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"minGarage","hash":{},"data":data,"loc":{"start":{"line":52,"column":44},"end":{"line":52,"column":57}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"maxGarage") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":57},"end":{"line":52,"column":95}}})) != null ? stack1 : "")
    + "</span>\r\n            <span class=\"markerDetail-label\">Garage</span>\r\n        </li>\r\n        ";
},"24":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <a href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"CommunityLink") || (depth0 != null ? lookupProperty(depth0,"CommunityLink") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"CommunityLink","hash":{},"data":data,"loc":{"start":{"line":61,"column":17},"end":{"line":61,"column":34}}}) : helper)))
    + "\" class=\"btn btn-primary marker-community-link\"\r\n            data-analytics=\"map-nearby-communities|window-info|view-community\" data-analytics-listing-cta=\"true\"\r\n            data-analytics-listing-type=\"community\">View Community</a>\r\n";
},"26":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"CommunityLink") || (depth0 != null ? lookupProperty(depth0,"CommunityLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CommunityLink","hash":{},"data":data,"loc":{"start":{"line":66,"column":17},"end":{"line":66,"column":34}}}) : helper)))
    + "#qmi\" class=\"btn btn-default marker-qmi-link\"\r\n            data-analytics=\"map-nearby-communities|window-info|view-community-qmi\" data-analytics-listing-cta=\"true\"\r\n            data-analytics-listing-type=\"community\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"inventoryCount") || (depth0 != null ? lookupProperty(depth0,"inventoryCount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inventoryCount","hash":{},"data":data,"loc":{"start":{"line":68,"column":52},"end":{"line":68,"column":70}}}) : helper)))
    + " Quick Move-Ins Available</a>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"info-window-nearby-template\" class=\"Map-markerContent clearfix\" data-analytics-community-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"communityId") || (depth0 != null ? lookupProperty(depth0,"communityId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"communityId","hash":{},"data":data,"loc":{"start":{"line":1,"column":102},"end":{"line":1,"column":117}}}) : helper)))
    + "\"\r\n    data-analytics-community-brand=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"brandName") || (depth0 != null ? lookupProperty(depth0,"brandName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"brandName","hash":{},"data":data,"loc":{"start":{"line":2,"column":36},"end":{"line":2,"column":49}}}) : helper)))
    + "\">\r\n    <h3 class=\"marker-name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":3,"column":28},"end":{"line":3,"column":36}}}) : helper)))
    + "</h3>\r\n    <hr>\r\n    <ul class=\"clearfix\">\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCommunitySoldOut") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":29,"column":15}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"minBedrooms") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":8},"end":{"line":36,"column":15}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"minBathrooms") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":8},"end":{"line":43,"column":15}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"minGarage") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":45,"column":8},"end":{"line":55,"column":15}}})) != null ? stack1 : "")
    + "\r\n    </ul>\r\n\r\n    <div class=\"marker-links\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"CommunityLink") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":8},"end":{"line":64,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"inventoryCount") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":8},"end":{"line":69,"column":15}}})) != null ? stack1 : "")
    + "    </div>\r\n\r\n</div>\r\n";
},"useData":true});