var templater = require("handlebars/runtime")["default"].template;module.exports = templater({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <span class=\"LocalAreaInfoWindow__rating\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"location") : depth0)) != null ? lookupProperty(stack1,"Rating") : stack1), depth0))
    + "</span>\r\n\r\n      <span class=\"LocalAreaInfoWindow__rating-stars\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"goldStars") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":13,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"grayStars") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":16,"column":17}}})) != null ? stack1 : "")
    + "      </span>\r\n\r\n      <span class=\"LocalAreaInfoWindow__total-ratings\">("
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"location") : depth0)) != null ? lookupProperty(stack1,"TotalRatings") : stack1), depth0))
    + ")</span>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "          <i class=\"LocalAreaInfoWindow__star font-icon-star-filled info-window-gold-star\"></i>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "          <i class=\"LocalAreaInfoWindow__star font-icon-star-filled info-window-gray-star\"></i>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <span class=\"LocalAreaInfoWindow__no-reviews\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"noReviewsLabel") || (depth0 != null ? lookupProperty(depth0,"noReviewsLabel") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"noReviewsLabel","hash":{},"data":data,"loc":{"start":{"line":21,"column":52},"end":{"line":21,"column":70}}}) : helper)))
    + "</span>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"info-window-local-area-template\" class=\"Map-markerContent LocalAreaInfoWindow hidden-xs clearfix\">\r\n  <h4 class=\"LocalAreaInfoWindow__location-name\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":2,"column":49},"end":{"line":2,"column":57}}}) : helper)))
    + "</h4>\r\n\r\n  <div class=\"LocalAreaInfoWindow__distance\">"
    + alias2(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"location") : depth0)) != null ? lookupProperty(stack1,"DistanceFromCommunity") : stack1), depth0))
    + " Miles</div>\r\n\r\n  <div class=\"LocalAreInfoWindow__rating-data d-inline-flex justify-content-center align-items-end\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"location") : depth0)) != null ? lookupProperty(stack1,"Rating") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":22,"column":11}}})) != null ? stack1 : "")
    + "  </div>\r\n\r\n</div>\r\n";
},"useData":true});